<template>
  <div class="main-view">
    <user-list></user-list>
  </div>
</template>

<script>
import UserList from '@/components/users/UserList.vue';

export default {
  name: 'UserListView',
  components: {
    UserList,
  },
};
</script>

<style>

</style>
