<template>
  <div>
      <b-table
        @select="selected"
        class="table-row"
        hoverable
        selectable
        :data="getUser">
          <b-table-column field="name" label="User Name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="email" current-sort label="User Email" sortable v-slot="props">
            {{ props.row.email }}
          </b-table-column>
          <b-table-column field="blocked" current-sort
          label="Blocked" sortable v-slot="props">
            {{ props.row.blocked? 'Yes' : 'No' }}
          </b-table-column>
          <b-table-column field="lastLogin" current-sort label="Last Login" sortable v-slot="props">
            {{ props.row.lastLogin || 'Never' }}
          </b-table-column>
      </b-table>
    </div>
</template>
<script>

export default {
  name: 'userInfo',
  props: {
    users: Array,
  },
  methods: {
    selected(item) {
      this.$router.push({ name: 'userEdit', params: { userId: `${item.userId}` } });
    },
  },
  mounted() {
    this.dataService = this.$dataService(this.$route.params.tenant);
  },
  data() {
    return {
      filterableFields: [{
        id: 'name',
        value: 'Name',
        type: 'string',
      }],
      visible: false,
      total: 0,
      isLoading: false,
      sortField: 'name',
      sortOrder: 'ascending',
      defaultSortOrder: 'ascending',
      page: 1,
      pageSize: 10,
    };
  },
  computed: {
    getUser() {
      return this.users;
    },
  },
};
</script>

<style lang="scss" scoped>
  .bottom-padding {
    padding: 0;
    padding-bottom: 1.5rem;
  }
</style>
