<template>
  <div>
     <div>
        <div class="">
          <button
            aria-label="Create User"
            class="button is-primary"
            v-on:click="createUser">
            <b-icon icon="plus-box"
              size="is-small">
            </b-icon>
            <p>Create User</p>
          </button>
        </div>
      </div><br>
    <user-info :users="users">
    </user-info>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="no-more"><br>Displaying all users</div>
      <div slot="no-results">No users found</div>
    </infinite-loading>
  </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';
import UserInfo from './UserInfo.vue';

export default {
  name: 'userList',
  components: { InfiniteLoading, UserInfo },
  data() {
    return {
      users: [],
      page: 0,
    };
  },
  methods: {
    createUser() /* istanbul ignore next */ {
      this.$router.push({ path: 'userCreate' });
    },
    infiniteHandler($state) /* istanbul ignore next */ {
      const dataService = this.$dataService(this.$route.params.tenant);
      const query = { page: this.page, countPerPage: 20 };

      dataService.users
        .list(query)
        .then((result) => {
          const users = result.data;

          if (users.length) {
            this.page += 1;
            this.users = this.users.concat(users);
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },
  },
};
</script>
<style scoped>
dt {

    display: block;
    float: left;
    min-width: 100px;
}

dt::after {
  content: ':'
}
</style>
